<template>
  <select-base
    v-model="value"
    :disabled="disabled"
    :require="require_"
    :multiple="multiple"
    :label="'Certificado Digital A1'"
    :label-select="'nome'"
    :carregar="carregarCertificados"
    @input="enviarParaPai"
  />
</template>

<script>
import api from '@/app/certificado-digital/shared/services/api'
import '@core/scss/vue/libs/vue-select.scss'

export default {
  name: 'CertificadoSelect',
  components: {
    SelectBase: () => import('@app/shared/components/select-base/SelectBase.vue'),
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    require: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      filtros: [
        {
          coluna: 'Nome',
          valor: '',
        },
      ],
    }
  },
  computed: {

    require_: {
      get() {
        return this.require
      },
    },
  },
  methods: {
    carregarCertificados(filtros) {
      return api.getCertificadosResumidos(filtros)
    },
    enviarParaPai() {
      this.$emit('input', this.value)
    },
  },
}
</script>
